<template>
  <v-card>
    <v-card-title>
      <div class="head" v-if="gameData">
        <img :src="IMAGE_URL + gameData.imageUrl" >
        <h1>{{ gameData.name }}</h1>
      </div>
      <v-spacer />
      <div>
        <create-update-catalogs-component :game-type-id="gameData.gameTypeId" @updateParent="fetch" />
      </div>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="datas"
      mobile-breakpoint="0"
      :hide-default-footer="true"
      :disable-pagination="true"
    >
      <template v-slot:item="row">
        <tr>
          <td>
            <v-avatar>
              <img :src="IMAGE_URL + row.item.catalogImageUrl" alt="" />
            </v-avatar>
          </td>
          <td>{{ row.item.catalogName }}</td>
          <td>{{ row.item.catalogSellingPrice }}</td>
          <td>{{ row.item.catalogCapitalPrice }}</td>
          <td>{{ row.item.catalogBeforeDiscountPrice }}</td>
          <td>-{{ row.item.catalogDiscountPercentage }} %</td>
          <td>
            <div>
              <create-update-catalogs-component :game-type-id="gameData.gameTypeId" :update-data="row.item" @updateParent="fetch" />
            </div>
            <div>
              <v-btn
                small
                depressed
                outlined
                color="red"
                @click="deleteData(row.item.catalogId)"
              >
                HAPUS
              </v-btn>
            </div>
          </td>
        </tr>
      </template></v-data-table
    >
  </v-card>
</template>

<script>
import axios from "axios";
import CreateUpdateCatalogsComponent from "../components/CreateUpdateCatalogsComponent.vue";

export default {
  name: "CatalogsDetail",

  components: { CreateUpdateCatalogsComponent },

  data() {
    return {
      IMAGE_URL: process.env.VUE_APP_IMAGE_URL,
      headers: [
        { text: "Image", value: "catalogImageUrl" },
        { text: "Item Name", value: "catalogName" },
        { text: "Harga Jual", value: "catalogSellingPrice" },
        { text: "Harga Modal", value: "catalogCapitalPrice" },
        { text: "Harga Sebelum Diskon", value: "catalogBeforeDiscountPrice" },
        { text: "Persentase Diskon", value: "catalogDiscountPercentage" },
        { text: "Action"},
      ],
      datas: [],
      gameData: null
    };
  },

  methods: {
    fetch() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);
      axios
        .create({ withCredentials: false })
        .get(
          `${process.env.VUE_APP_API_URL}/v2/catalog?game_slug=${this.$route.params.slug}`,
          {
            headers: { Authorization: AuthStr },
          }
        )
        .then((response) => {
          this.datas = response.data.result;
          this.gameData = response.data.result[0].game_type;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
        });
    },

    deleteData(id) {
      let doConfirm = window.confirm(`Sure to delete this?`);

      if (doConfirm) {
        let config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };

        axios
          .create({ withCredentials: false })
          .delete(
            `${process.env.VUE_APP_API_URL}/v2/catalog/${id}/delete`,
            config
          )
          .then((response) => {
            this.fetch();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            this.$toast.warning(
              error.response.data.message || "Unauthorized"
            );
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
.head {
  display: flex;
  gap: 20px;
  align-items: center;
}
.head > img {
  height: 100px;
  border-radius: 15px;
}
</style>